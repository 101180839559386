
import CreateCollection from "./views/CreateCollection/index.js";
import Drops from "./views/Drops/index.js";
import MyCollection from "./views/MyCollection/index.js";
import SingleCollection from "./views/SingleCollection/index.js";
import EditCollection from "./views/EditCollection/index.js";
import CreateItem from "./views/CreateItem/index.js";
import MintCollection from "./views/MintCollection/index.js";
import ItemDetails from "./views/ItemDetails/index.js";
import WhiteList0xBurn from "./views/WhiteList0xBurn/index.js";
import WhiteListNFT from "./views/WhiteListNFT/index.js";


var routes = [
  {
    layout: "/home",
    path: "/",
    component: MyCollection,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/mycollection",
    name: "My Collections",
    component: MyCollection,
    imgUrl: "/images/collections-icon.png",
  },
  {
    layout: "/home",
    path: "/collection/:chain/:tokenAddress",
    component: SingleCollection,
    hidden: true,
  },
  // {
  //   layout: "/home",
  //   path: "/singlecollection",
  //   component: SingleCollection,
  //   hidden: true,
  // },
  {
    layout: "/home",
    path: "/itemdetail/:id",
    component: ItemDetails,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/editcollection/:chain/:tokenAddress",
    component: EditCollection,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/createcollection",
    name: "Create",
    component: CreateCollection,
    imgUrl: "/images/create-icon.png",
  },
  {
    layout: "/home",
    path: "/createitem",
    component: CreateItem,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/drops",
    name: "NFTS",
    component: Drops,
    imgUrl: "/images/drops-icon.png",
  },
  {
    layout: "/home",
    path: "/mintcollection/:chain/:tokenAddress",
    component: MintCollection,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/WhiteList0xBurn",
    name: "WhiteList Token",
    component: WhiteList0xBurn,
    imgUrl: "/images/approved.svg",
  },
  {
    layout: "/home",
    path: "/WhiteListNFT",
    name: "WhiteList NFT",
    component: WhiteListNFT,
    imgUrl: "/images/approved.svg",
  },
];

export default routes;